const initialProps = {
    view: true,    
    isEdit: false,
    modal: false,
    stock: [],
    providers: [],
    editStock: []
};

const reducer = (state = initialProps, action) =>{
    switch (action.type){
        case "CHANGE_STATE_VIEW":
            return {...state, view: !state.view};
        case "LOAD_STOCK":
            return {...state, stock: action.stock};
        case "EDIT_STOCK":
           return {...state, editStock: action.editStock}; 
       case "LOAD_PROVIDERS_STOCK":
           return {...state, providers: action.providers};     
        case "IS_EDIT":
            return {...state, isEdit: !state.isEdit};
        case "CHANGE_STATE_MODAL":
            return {...state, modal: !state.modal}
       case "STATE_INITIAL_STOCK":
           return {...state, view: initialProps.view,
                modal: false,
               isEdit: initialProps.isEdit,
               editStock: initialProps.editStock};
       default:
           return state;
   }      
};
export default reducer;
const initialProps = {
    user: []
};

//CREAR ARCHIVO DE CONSTANTES PARA LOS CASES DE LOS REDUCERS

const reducer = (state = initialProps, action) => {
    switch (action.type){
        case "SAVE_USER_LOGIN":
            return {...state, user: action.user };
        default:
            return state;     
    }
};

export default reducer;
const initialProps = {
    view: true,    
    modal: false,
    modalView: false,
    isEdit: false,
    editWorkOrder: [],
    clients: [],
    areas: [],
    resources: [],
    maintenancePlans: [],
    workOrders: [],
    workOrdersActive: [],
    workOrdersCloses: [],
    workOrdersFinished: [],
    workOrdersPending: [],
    tasks: [],
    employees: [],
    stock: [],
    costos: [],
    idWO: 0
};

const reducer = (state = initialProps, action ) =>{
    switch (action.type){
         case "CHANGE_STATE_VIEW":
             return {...state, view: !state.view};
        case "CHANGE_STATE_MODAL":
                return {...state, modal: !state.modal};
        case "CHANGE_STATE_MODALVIEW":
                return {...state, modalView: !state.modalView};        
         case "LOAD_CLIENTS":
             return {...state, clients: action.clients};
         case "EDIT_WORKORDER":
            return {...state, editWorkOrder: action.editWorkOrder}; 
        case "LOAD_AREAS":
            return {...state, areas: action.areas};
        case "LOAD_RESOURCES":
            return {...state, resources: action.resources};     
        case "LOAD_MAINTENANCEPLANS":
            return {...state, maintenancePlans: action.maintenancePlans};
        case "LOAD_WORKORDERS":
            return {...state, workOrders: action.workOrders};
        case "LOAD_WORKORDERS_ACTIVE":
            return {...state, workOrdersActive: action.workOrdersActive};
        case "LOAD_WORKORDERS_CLOSES":
            return {...state, workOrdersCloses: action.workOrdersCloses};
        case "LOAD_WORKORDERS_FINISHED":
            return {...state, workOrdersFinished: action.workOrdersFinished};
        case "LOAD_WORKORDERS_PENDING":
            return {...state, workOrdersPending: action.workOrdersPending};
        case "LOAD_TASKS":
            return {...state, tasks: action.tasks};
        case "LOAD_STOCK":
            return {...state, stock: action.stock};   
        case "LOAD_EMPLOYEES":
            return {...state, employees: action.employees};  
        case "IS_EDIT":
            return {...state, isEdit: !state.isEdit};
        case "LOAD_COST":
            return {...state, costos: action.costos};
        case "ADD_IDWO":
            return {...state, idWO: action.idWO};
        case "IS_NEW_WORKORDER":
             return {...state, isEdit: false};
        case "STATE_INITIAL_WORKORDER":
            return {...state, view: initialProps.view,
                isEdit: initialProps.isEdit,
                editWorkOrder: initialProps.editWorkOrder,
                maintenancePlans: initialProps.maintenancePlans};
        default:
            return state;
    }      

};
export default reducer;
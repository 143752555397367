const initialProps = {
    add: false,
    modal: false,
    view: true,
    isEdit: false,
    maintenancePlans: [],
    maintenancePlan: [],
    resourceMaintenancePlan: [],
    clients: [],
    resources: [],
    areas: [],
    frequencies: [],
    tasks: [],
    selected: [],
    resourcesSelected: []
};


const reducer = (state = initialProps, action) => {
    switch (action.type){
        case "CHANGE_STATE_VIEW":
            return {...state, view: !state.view};
        case "CHANGE_VIEW_ADD":
            return {...state, add: !state.add};    
        case "CHANGE_STATE_MODAL_PLAN":
            return {...state, modal: true};    
        case "LOAD_MAINTENANCEPLANS":
            return {...state, maintenancePlans: action.maintenancePlans};
        case "EDIT_MAINTENANCEPLAN":
            return {...state, maintenancePlan: action.maintenancePlan};
        case "LOAD_FREQUENCIES":
            return {...state, frequencies: action.frequencies};
        case "LOAD_RESOURCEMAINTENANCEPLAN":
            return {...state, resourceMaintenancePlan: action.resourceMaintenancePlan};    
        case "IS_EDIT":
                return {...state, isEdit: true };
        case "LOAD_CLIENTS_MAINTENANCEPLAN":
            return {...state, clients: action.clients};
        case "LOAD_AREAS_MAINTENANCEPLAN":
            return {...state, areas: action.areas};
        case "LOAD_RESOURCES_MAINTENANCEPLAN":
            return {...state, resources: action.resources};
        case "LOAD_TASKS_MAINTENANCEPLAN":
            return {...state, tasks: action.tasks}; 
        case "LOAD_SELECTED_MAINTENANCEPLAN":
            return {...state, selected: action.selected};    
        case "LOAD_SELECTED_RESOURCESPLAN":
            return {...state, resourcesSelected: action.resourcesSelected};    
        case "IS_NEW_MAINTENANCEPLAN":
            return {...state, isEdit: false};
        case "STATE_INITIAL_MAINTENANCEPLAN":
            return {...state, view: initialProps.view,
                    isEdit: initialProps.isEdit,
                    add: initialProps.add,
                    selected: initialProps.selected,
                    resourcesSelected: initialProps.resourcesSelected,
                    modal: initialProps.modal};
        default:
            return state;
    }
};

export default reducer;
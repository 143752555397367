const initialProps = {
    view: true,    
    viewQuotation: false,
    isEdit: false,
    quotations: [],
    items: [],
    clients: [],
    editQuotation: [],
    user: []
};
const reducer = (state = initialProps, action)=>{
    switch(action.type){
        case "CHANGE_STATE_VIEW":
            return {...state, view: !state.view};
        case "LOAD_QUOTATION":
            return {...state, quotations: action.quotations};
        case "LOAD_ITEMS":
            return {...state, items: action.items};
        case "LOAD_USER":
            return {...state, user: action.user};
        case "LOAD_CLIENTS_QUOTATION":
            return {...state, clients: action.clients};
        case "CHANGE_STATE_VIEW_QUOTATION":
            return {...state, viewQuotation: !state.viewQuotation};
        case "EDIT_QUOTATION":
           return {...state, editQuotation: action.editQuotation};      
        case "IS_EDIT":
            return {...state, isEdit: !state.isEdit};
        case "IS_NEW_QUOTATION":
            return {...state, isEdit: false};
       case "STATE_INITIAL_QUOTATION":
           return {...state, view: initialProps.view,
                modal: initialProps.modal,
                isEdit: initialProps.isEdit};
       default:
           return state;

    }

};
export default reducer;
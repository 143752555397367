import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';


// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const AdminLayout = React.lazy(() => import('./containers/admin/AdminLayout'));
const UserLayout = React.lazy(() => import('./containers/user/UserLayout'));


// Pages
const Login = React.lazy(() => import('./containers/DefaultLayout/LoginInitial'));
const Profile = React.lazy(() => import('./components/Pages/Profile/Profile'));
const Page404 = React.lazy(() => import('./components/Pages/Page404'));
const Page500 = React.lazy(() => import('./components/Pages/Page500'));




class App extends Component {



  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />
              <Route path="/admin" name="Admin Page" render={props => <AdminLayout {...props}/>} />
              <Route path="/user" name="User Page" render={props => <UserLayout {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/template" name="Template" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;

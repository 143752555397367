const initialProps = {
    view: true,    
    isEdit: false,
    providers: [],
    products: [],
    editProduct: []
};
const reducer = (state = initialProps, action ) =>{
    switch (action.type){
         case "CHANGE_STATE_VIEW":
             return {...state, view: !state.view};
         case "LOAD_PRODUCTS":
             return {...state, products: action.products};
         case "EDIT_PRODUCT":
            return {...state, editProduct: action.editProduct}; 
        case "LOAD_PROVIDERS_PRODUCT":
            return {...state, providers: action.providers};     
         case "IS_EDIT":
             return {...state, isEdit: !state.isEdit};
         case "IS_NEW_PRODUCT":
             return {...state, isEdit: false};
        case "STATE_INITIAL_PRODUCT":
            return {...state, view: initialProps.view,
                isEdit: initialProps.isEdit,
                editProduct: initialProps.editProduct};
        default:
            return state;
    }      

};
export default reducer;

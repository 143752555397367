
const initialProps = {
    workOrdersTaked: [],
    workOrdersActive: [],
    workOrdersEvaluation: [],
    workOrders: []
};

const reducer = (state = initialProps, action ) =>{
    switch (action.type){
        case "LOAD_WORKORDERS_TAKED":
            return {...state, workOrdersTaked: action.workOrdersTaked};
        case "LOAD_WORKORDERS_ACTIVE":
            return {...state, workOrdersActive: action.workOrdersActive};
        case "LOAD_WORKORDERS_EVALUATION":
            return {...state, workOrdersEvaluation: action.workOrdersEvaluation};  
        case "LOAD_WORKORDERS":
            return {...state, workOrders: action.workOrders};   
        
        default:
            return state;
    }      
};

export default reducer;
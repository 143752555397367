import { combineReducers, applyMiddleware } from 'redux';
import redxSaga from 'redux-saga';
//reducers
import loginReducers from './loginReducers';
import employeeReducers from './employeeReducers';
import clientReducers from './clientReducers';
import areaReducers from './areaReducers';
import resourceReducers from './resourceReducers';
import maintenancePlanReducers from './maintenancePlanReducers';
import productReducers from './productReducers';
import providerReducers from './providerReducers';
import quotationReducers from './quotationReducers';
import stockReducers from './stockReducers';
import workOrderReducers from './workOrderReducers';
import dashboardUserReducers from './dashboardUserReducers';


export default combineReducers({
    userLogin: loginReducers,
    employeeReducer: employeeReducers,
    clientReducer: clientReducers,
    areaReducer: areaReducers,
    resourceReducer: resourceReducers,
    maintenancePlanReducer: maintenancePlanReducers,
    productReducer: productReducers,
    providerReducer: providerReducers,
    quotationReducer: quotationReducers,
    stockReducer: stockReducers,
    workOrderReducer: workOrderReducers,
    dashboardUserReducer: dashboardUserReducers
});
const initialProps = {
    view: true,    
    isEdit: false,
    providers: [],
    editProvider: [],
    locationProvider: []
};
const reducer = (state = initialProps, action)=>{
    switch(action.type){
        case "CHANGE_STATE_VIEW":
            return {...state, view: !state.view};
        case "LOAD_PROVIDERS":
            return {...state, providers: action.providers};
        case "EDIT_PROVIDER":
           return {...state, editProvider: action.editProvider};  
        case "LOAD_LOCATION_PROVIDER":
            return {...state, locationProvider: action.locationProvider}    
        case "IS_EDIT":
            return {...state, isEdit: !state.isEdit};
        case "IS_NEW_PROVIDER":
            return {...state, isEdit: false};
       case "STATE_INITIAL_PROVIDER":
           return {...state, view: initialProps.view,
               isEdit: initialProps.isEdit,
                editProvider: initialProps.editProvider};
       default:
           return state;

    }

};
export default reducer;
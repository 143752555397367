const initialProps = {
    view: true,
    isEdit: false,
    resource: [],
    clients: [],
    resources: [],
    areas: [],
    types: []
};

const reducer = (state = initialProps, action) => {
    switch (action.type){
        case "CHANGE_STATE_VIEW":
            return {...state, view: !state.view};
        case "LOAD_RESOURCES":
            return {...state, resources: action.resources};
        case "LOAD_TYPES":
            return {...state, types: action.types};
        case "IS_EDIT":
            return {...state, isEdit: true };
        case "LOAD_CLIENT_RESOURCE":
            return {...state, clients: action.clients};
        case "LOAD_AREAS_RESOURCE":
            return {...state, areas: action.areas};
        case "EDIT_RESOURCE":
            return {...state, resource: action.resource};
        case "IS_NEW_RESOURCE":
            return {...state, isEdit: false};
        case "STATE_INITIAL_RESOURCE":
            return {...state, view: initialProps.view,
                    isEdit: initialProps.isEdit};
        default:
            return state;
    }
};

export default reducer;

const initialProps = {
    view: true,
    isEdit: false,
    areas: [],
    editArea: [],
    clients: [],
    areaPerClient: []
};

const reducer = (state = initialProps, action ) =>{
    switch (action.type){
        case "CHANGE_STATE_VIEW":
            return {...state, view: !state.view};
        case "EDIT_AREA":
            return {...state, editArea: action.editArea};
        case "IS_EDIT":
            return {...state, isEdit: !state.isEdit}; 
        case "LOAD_AREAS":
            return {...state, areas: action.areas };
        case "LOAD_CLIENTS_AREAS":
            return {...state, clients: action.clients};    
        case "IS_NEW_AREA":
            return {...state, isEdit: false};
        case "INITIAL_STATE_AREA":
            return {...state, view: initialProps.view,
                    isEdit: initialProps.isEdit
                    };
        default:
            return state;
    }      
};

export default reducer;

const initialProps = {
    view: true,
    isEdit: false,
    users: [],
    editUser: [],
    locationUser: []
};

//CREAR ARCHIVO DE CONSTANTES PARA LOS CASES DE LOS REDUCERS

const reducer = (state = initialProps, action) => {
    switch (action.type){
        case "CHANGE_STATE_VIEW":
            return {...state, view: !state.view };
        case "LOAD_USERS":
            return {...state, users: action.users}; 
        case "EDIT_USER":
            return {...state, editUser: action.editUser};
        case "LOAD_LOCATION_EMPLOYEE":
            return {...state, locationUser: action.locationUser};
        case "IS_EDIT":
            return {...state, isEdit: !state.isEdit}; 
        case "IS_NEW_USER":
            return {...state, isEdit: false};   
        case "STATE_INITIAL_EMPLOYEE":
            return {...state, view: initialProps.view,
                    isEdit: initialProps.isEdit};   
        default:
            return state;     
    }
};

export default reducer;
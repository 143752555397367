
const initialProps = {
    view: true,
    modal: false,
    isEdit: false,
    clients: [],
    editClient: [],
    locationClient: []
};


const reducer = (state = initialProps, action ) =>{
    switch (action.type){
        case "CHANGE_STATE_VIEW":
            return {...state, view: !state.view};
        case "LOAD_CLIENTS":
            return {...state, clients: action.clients};
        case "CHANGE_STATE_MODAL":
            return {...state, modal: !state.modal};
        case "EDIT_CLIENT":
            return {...state, editClient: action.editClient};
        case "LOAD_LOCATION_CLIENT":
            return {...state, locationClient: action.locationClient}
        case "IS_EDIT":
            return {...state, isEdit: !state.isEdit};
        case "IS_NEW_CLIENT":
            return {...state, isEdit: false};
        case "STATE_INITIAL_CLIENT":
            return {...state, view: initialProps.view,
                isEdit: initialProps.isEdit,
                modal: initialProps.modal};
        default:
            return state;
    }      
};

export default reducer;